// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accept: 'application/json',
  content_type: 'application/json',

  // Dev

  // apiUrl: 'http://103.149.154.53/ViewUs/public/api',
  // mediaUrl: "https://viewusimages.s3.amazonaws.com",
  // mediaUrlOrg: "https://viewusimages.s3.amazonaws.com/dev/original/",
  // mediaUrlThumb: "https://viewusimages.s3.amazonaws.com/dev/thumbnail/",

  // QA

  // apiUrl: "http://103.149.154.53/ViewUsQa/public/api",
  // mediaUrl: "http://103.149.154.53/ViewUsQa/public/storage",
  // mediaUrlOrg: "http://103.149.154.53/ViewUsQa/public/storage/qa/original/",
  // mediaUrlThumb: "http://103.149.154.53/ViewUsQa/public/storage/qa/thumbnail/",

  // Client

  apiUrl: "https://api.viewus.com/api",
  mediaUrl: "https://viewusimages.s3.amazonaws.com",
  mediaUrlOrg: "https://viewusimages.s3.amazonaws.com/dev/original/",
  mediaUrlThumb: "https://viewusimages.s3.amazonaws.com/dev/thumbnail/",

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
