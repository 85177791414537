import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { GuestGuard } from "./guards/guest.guard";

const routes: Routes = [
  {
    path: '',
    //canActivate: [GuestGuard],
    loadChildren: () =>
      import('./modules/guest-layout/guest-layout.module').then(
        (mod) => mod.GuestLayoutModule
      ),
  },
  {
    path: '',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/portal-layout/portal-layout.module').then(
        (mod) => mod.PortalLayoutModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }