import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoProcessingService {

    constructor(
        @Inject(DOCUMENT) private document: Document
    ) { }

    public promptForVideo(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            // make file input element in memory
            const fileInput: HTMLInputElement = this.document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'video/mp4,video/x-m4v,video/*';
            // for camera only
            // fileInput.setAttribute('capture', 'camera');
            // fileInput['capture'] = 'camera';
            fileInput.addEventListener('error', event => {
                reject(event.error);
            });
            fileInput.addEventListener('change', event => {
                if (fileInput.files)
                    resolve(fileInput.files[0]);
            });
            // prompt for video file
            fileInput.click();
        });
    }


    public prompMultipleVideo(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            // make file input element in memory
            const fileInput: HTMLInputElement = this.document.createElement('input');
            fileInput.type = 'file';
            fileInput.multiple = true;
            fileInput.accept = 'video/*';
            //fileInput.setAttribute('capture', 'camera');
            // fileInput['capture'] = 'camera';
            fileInput.addEventListener('error', event => {
                reject(event.error);
            });
            fileInput.addEventListener('change', async (event) => {
                console.log('fileInput.files', fileInput.files)
                for (let i = 0; i <= fileInput?.files?.length - 1; i++) {
                    console.log('alsjajs', fileInput?.files[i], i);
                    await resolve(fileInput?.files[i]);
                    // this.prompMultipleVideo();
                    console.log('one')

                }
                // if (fileInput.files)
                //     resolve(fileInput.files);
            });
            // prompt for video file
            fileInput.click();
        });
    }


    public generateThumbnail(videoFile: Blob): Promise<string> {
        const video: HTMLVideoElement = this.document.createElement('video');
        const canvas: HTMLCanvasElement = this.document.createElement('canvas');
        const context: any = canvas.getContext('2d');
        return new Promise<string>((resolve, reject) => {
            canvas.addEventListener('error', reject);
            video.addEventListener('error', reject);
            video.addEventListener('canplay', event => {
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                resolve(canvas.toDataURL());
            });
            if (videoFile.type) {
                video.setAttribute('type', videoFile.type);
            }
            video.preload = 'auto';
            video.src = window.URL.createObjectURL(videoFile);
            localStorage.setItem('video', video.src);
            console.log('video', video.src);
            video.load();
        });
    }

}