import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class GuestGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //console.log(this.auth.guest());
    if (this.auth.guest()) {
      return true;
    }
    let user = this.auth.user();
    // logged in so return false
    this.router.navigate(["/dashboard"]);
    return false;
  }

}