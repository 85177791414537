import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AlertService } from "./services/alert";
import { AuthService } from "./services/auth";
import { RequestInterceptorService } from "./services/http.interceptor";
import { VideoProcessingService } from './services/video-processing-service';


import { NgOtpInputModule } from 'ng-otp-input';
import { AuthGuard } from "./guards/auth.guard";
import { GuestGuard } from "./guards/guest.guard";
import { LoaderComponent } from "./shared/loader/loader.component";
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy, } from "@angular/common";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { VideoRecordingService } from './services/video-recording.service';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    NgOtpInputModule,
    AutocompleteLibModule
  ],
  providers: [
    AlertService,
    AuthGuard,
    GuestGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthService,
    VideoProcessingService,
    VideoRecordingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }