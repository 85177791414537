import { Component, OnInit, TemplateRef } from "@angular/core";
import { AlertService } from "./services/alert";
import { LoaderService } from "./services/loader";
import { RouteConfigLoadStart, RouteConfigLoadEnd, Router, NavigationEnd, } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ViewUS-Admin';
  loading = false;

  constructor(
    private router: Router,
    public toastService: AlertService,
    private loader: LoaderService,
  ) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.state(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.state(false);
      }
    });
  }

  ngAfterViewInit() {
    this.loader.attach.subscribe((data) => {
      setTimeout(() => {
        this.loading = data;
      }, 100);
    });
  }

}