import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class AuthService {
  private info = new BehaviorSubject<boolean>(false);
  private currentUser: any;
  private currentToken: any;

  currentState = this.info.asObservable();

  guest() {
    if (this.token() && this.user()) {
      this.state(false);
      return false;
    }
    this.state(true);
    return true;
  }

  user() {
    let user: any = localStorage.getItem("_currentAdminUser");
    return JSON.parse(user);
  }

  state(state: boolean) {
    this.info.next(state);
  }

  token() {
    return localStorage.getItem("authtokenAdmin") || "";
  }

  login(data: any, remeber = false) {
    this.state(true);

    this.currentUser = data.data;
    this.currentToken = data.data.token;

    let user = JSON.stringify(data.data);
    let token = this.currentToken;
    console.log(this.currentToken);

    localStorage.setItem("_currentAdminUser", user);
    localStorage.setItem("authtokenAdmin", token);
  }

  logout() {
    // remove local storage 
    localStorage.removeItem("_currentAdminUser");
    localStorage.removeItem("authtokenAdmin");
  }

  updateUserInfo(user: any) {
    let userData = JSON.parse(localStorage.getItem('_currentAdminUser') || '');
    console.log("user<<<>>>", user);
    console.log("userUpate<<<>>>", userData);
    userData.user_info.name = user.name;
    userData.user_info.username = user.username;
    userData.user_info.profile_image = user.profile_image;

    localStorage.setItem("_currentAdminUser", JSON.stringify(userData));
    console.log("this.user", this.user);


  }

}